/* ===========================
基本設定
=========================== */
html {
  font-size: 62.5%;
  font-family: 'Helvetica Neue', Helvetica, Arial,"游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", YuGothic,-apple-system,BlinkMacSystemFont, 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, 'MS ゴシック', 'MS Gothic', sans-serif;
}
a {
  color: inherit;
	text-decoration: none;
	transition: all.2s;
	&:hover{
		opacity: .7;
	}
}
img {
	max-width: 100%;
	vertical-align: bottom;
}
section {
  display: block;
}
.bold {
  font-weight: bold;
}

//数字入力の上下矢印を消す
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance:textfield;
}

/* SPここから */
@media only screen and (max-width: 750px) {
a {
  transition: all 0s;
  &:hover{
    opacity: 1;
  }
}
}

/* ===========================
画面幅による表示・非表示
=========================== */
.tb_only {
  display: none !important;
}
.sp_only {
  display: none !important;
}
.pc_only {
  display: block !important;
}

/* TBここから */
@media only screen and (min-width: 751px) and (max-width: 1220px) {
.pc_only {
  display: none !important;
}
.sp_only {
  display: none !important;
}
.tb_only {
  display: block !important;
}
}

/* SPここから */
@media only screen and (max-width: 750px) {
.pc_only {
  display: none !important;
}
.tb_only {
  display: none !important;
}
.sp_only {
  display: block !important;
}
}

/* ===========================
タイトル
=========================== */
.ttl01 {
  text-align: center;
  font-size: 3.6rem;
  line-height: 1.2;
  letter-spacing: .1em;
  font-weight: 400;
}
@media only screen and (min-width: 751px) and (max-width: 1220px) {
.ttl01 {
  font-size: 36/1000*100vw;
}
}
@media only screen and (max-width: 750px) {
.ttl01 {
  font-size: 48/750*100vw;
}
}

.ttl02 {
  font-size: 2.8rem;
  letter-spacing: .1em;
  text-align: center;
}
@media only screen and (max-width: 750px) {
.ttl02 {
  font-size: 40/750*100vw;
}
}

/* ===========================
パンくず
=========================== */
.breadcrumb {
  width: 100%;
  padding: 0 30px;
  margin-bottom: 30px;
  ol {
    display: flex;
    flex-wrap: wrap;
    li {
      font-size: 1.2rem;
      letter-spacing: .1em;
      &::after {
        content: ">";
        padding: 0 8px;
      }
      &:last-of-type {
        &::after {
          content: none;
        }
      }
      a {
        .pc_only,.tb_only {
          display: inline !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 751px) and (max-width: 1220px) {
.breadcrumb {
  padding: 0 30/1000*100vw;
  margin-bottom: 20px;
}
}

@media only screen and (max-width: 750px) {
.breadcrumb {
  padding: 0;
  margin-bottom: 0;
  ol {
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    li {
      flex-shrink: 0;
      font-size: 20/750*100vw;
      line-height: 0;
      height: 60/750*100vw;
      white-space: nowrap;
      overflow-y: hidden;
      a,span {
        padding: 0 16/750*100vw;
        line-height: 60/750*100vw;
        vertical-align: top;
      }
      &::after {
        content: "";
        display: inline-block;
        width: 15/750*100vw;
        height: 60/750*100vw;
        background-image: url(../../img/common/sp/arrow_breadcrumb.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        padding: 0;
      }
      &:last-of-type {
        &::after {
          content: none;
        }
      }
      a {
        .pc_only,.tb_only {
          display: none !important;
        }
      }
    }
  }
  ol::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
  }
}
}

/* ===========================
ボタン
=========================== */
.btn01 {
  text-align: center;
  a,span {
    display: inline-block;
    min-width: 260px;
    height: 54px;
    border: 1px solid #000;
    background-color: #000;
    font-size: 1.8rem;
    color: #fff;
    font-weight: bold;
    letter-spacing: .1em;
    line-height: 54px;
    text-align: center;
    padding: 0 40px;
    transition-duration: .2s;
    &:hover {
      opacity: 1;
      background-color: transparent;
      color: #000;
    }
  }
}
.btn02 {
  text-align: center;
  a,span {
    display: inline-block;
    min-width: 260px;
    height: 54px;
    border: 1px solid #fff;
    background-color: transparent;
    font-size: 1.8rem;
    color: #fff;
    font-weight: bold;
    letter-spacing: .1em;
    line-height: 54px;
    text-align: center;
    padding: 0 40px;
    transition-duration: .2s;
    &:hover {
      opacity: 1;
      background-color: #fff;
      color: #000;
    }
  }
}

//共通ボタン：バリエーション
.btn_common {
  text-align: center;
  a,span,input,button {
    display: inline-block;
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: .1em;
    text-align: center;
    padding: 0 30px;
    transition-duration: .2s;
    &:hover {
      opacity: 1 !important;
    }
  }
  //サイズバリエーション
  &.size_380_80_cv {
    a,span,input,button {
      min-width: 380px;
      height: 80px;
      line-height: 80px;
    }
  }
  &.size_340_80_cv {
    a,span,input,button {
      min-width: 340px;
      height: 80px;
      line-height: 80px;
    }
  }
  &.size_380_80 {
    a,span,input,button {
      min-width: 380px;
      height: 80px;
      line-height: 80px;
    }
  }
  &.size_340_80 {
    a,span,input,button {
      min-width: 340px;
      height: 80px;
      line-height: 80px;
    }
  }
  &.size_360_60 {
    a,span,input,button {
      min-width: 360px;
      height: 60px;
      line-height: 60px;
    }
  }
  &.size_290_60 {
    a,span,input,button {
      min-width: 290px;
      height: 60px;
      line-height: 60px;
    }
  }
  &.size_310_54 {
    a,span,input,button {
      min-width: 310px;
      height: 54px;
      line-height: 54px;
    }
  }
  &.size_290_54 {
    a,span,input,button {
      min-width: 290px;
      height: 54px;
      line-height: 54px;
    }
  }
  &.size_100_36 {
    a,span,input,button {
      min-width: 100px;
      height: 36px;
      line-height: 36px;
      font-size: 1.4rem;
    }
  }
  //タイプバリエーション
  &.btn_cv {
    a,span,input,button {
      color: #fff !important;
      background-color: #d3402c !important;
      border: 1px solid #d3402c !important;
      &:hover {
        background-color: #fb3325 !important;
        border: 1px solid #fb3325 !important;
      }
    }
  }
  &.btn_go_cv {
    a,span,input,button {
      color: #fff !important;
      background-color: #000 !important;
      border: 1px solid #000 !important;
      &:hover {
        background-color: #d3402c !important;
        border: 1px solid #d3402c !important;
      }
    }
  }
  &.btn_go {
    a,span,input,button {
      color: #fff !important;
      background-color: #000 !important;
      border: 1px solid #000 !important;
      &:hover {
        color: #000 !important;
        background-color: #fff !important;
      }
    }
  }
  &.btn_back {
    a,span,input,button {
      color: #000 !important;
      background-color: #fff !important;
      border: 1px solid #000 !important;
      &:hover {
        color: #fff !important;
        background-color: #000 !important;
      }
    }
  }
}

@media only screen and (min-width: 751px) and (max-width: 1220px) {
.btn01 {
  a,span {
    min-width: 260/1000*100vw;
    height: 54/1000*100vw;
    font-size: 18/1000*100vw;
    line-height: 54/1000*100vw;
    padding: 0 40/1000*100vw;
  }
}
.btn02 {
  a,span {
    min-width: 260/1000*100vw;
    height: 54/1000*100vw;
    font-size: 18/1000*100vw;
    line-height: 54/1000*100vw;
    padding: 0 40/1000*100vw;
  }
}
//共通ボタン：バリエーション
.btn_common {
  a,span,input,button {
    font-size: 18/1000*100vw;
    padding: 0 30/1000*100vw;
  }
  //サイズバリエーション
  &.size_380_80_cv {
    a,span,input,button {
      min-width: 380/1000*100vw;
      height: 80/1000*100vw;
      line-height: 80/1000*100vw;
    }
  }
  &.size_340_80_cv {
    a,span,input,button {
      min-width: 340/1000*100vw;
      height: 80/1000*100vw;
      line-height: 80/1000*100vw;
    }
  }
  &.size_380_80 {
    a,span,input,button {
      min-width: 380/1000*100vw;
      height: 80/1000*100vw;
      line-height: 80/1000*100vw;
    }
  }
  &.size_340_80 {
    a,span,input,button {
      min-width: 340/1000*100vw;
      height: 80/1000*100vw;
      line-height: 80/1000*100vw;
    }
  }
  &.size_360_60 {
    a,span,input,button {
      min-width: 360/1000*100vw;
      height: 60/1000*100vw;
      line-height: 60/1000*100vw;
    }
  }
  &.size_290_60 {
    a,span,input,button {
      min-width: 290/1000*100vw;
      height: 60/1000*100vw;
      line-height: 60/1000*100vw;
    }
  }
  &.size_310_54 {
    a,span,input,button {
      min-width: 310/1000*100vw;
      height: 54/1000*100vw;
      line-height: 54/1000*100vw;
    }
  }
  &.size_290_54 {
    a,span,input,button {
      min-width: 290/1000*100vw;
      height: 54/1000*100vw;
      line-height: 54/1000*100vw;
    }
  }
  &.size_100_36 {
    a,span,input,button {
      min-width: 100/1000*100vw;
      height: 36/1000*100vw;
      line-height: 36/1000*100vw;
      font-size: 14/1000*100vw;
    }
  }
}
}
@media only screen and (max-width: 750px) {
.btn01 {
  a,span {
    min-width: 360/750*100vw;
    height: 75/750*100vw;
    font-size: 24/750*100vw;
    line-height: 75/750*100vw;
    padding: 0 40/750*100vw;
  }
}
.btn02 {
  a,span {
    min-width: 360/750*100vw;
    height: 75/750*100vw;
    font-size: 24/750*100vw;
    line-height: 75/750*100vw;
    padding: 0 40/750*100vw;
  }
}
//共通ボタン：バリエーション
.btn_common {
  a,span,input,button {
    font-size: 24/750*100vw;
    padding: 0 60/750*100vw;
    transition-duration: 0s;
  }
  //サイズバリエーション
  &.size_380_80_cv {
    a,span,input,button {
      min-width: 100%;
      height: 140/750*100vw;
      line-height: 140/750*100vw;
      font-size: 30/750*100vw;
    }
  }
  &.size_340_80_cv {
    a,span,input,button {
      min-width: 100%;
      height: 140/750*100vw;
      line-height: 140/750*100vw;
      font-size: 30/750*100vw;
    }
  }
  &.size_380_80 {
    a,span,input,button {
      min-width: 280/750*100vw;
      height: 75/750*100vw;
      line-height: 75/750*100vw;
    }
  }
  &.size_340_80 {
    a,span,input,button {
      min-width: 280/750*100vw;
      height: 75/750*100vw;
      line-height: 75/750*100vw;
    }
  }
  &.size_360_60 {
    a,span,input,button {
      min-width: 280/750*100vw;
      height: 75/750*100vw;
      line-height: 75/750*100vw;
    }
  }
  &.size_290_60 {
    a,span,input,button {
      min-width: 280/750*100vw;
      height: 75/750*100vw;
      line-height: 75/750*100vw;
    }
  }
  &.size_310_54 {
    a,span,input,button {
      min-width: 280/750*100vw;
      height: 75/750*100vw;
      line-height: 75/750*100vw;
    }
  }
  &.size_290_54 {
    a,span,input,button {
      min-width: 280/750*100vw;
      height: 75/750*100vw;
      line-height: 75/750*100vw;
    }
  }
  &.size_100_36 {
    a,span,input,button {
      min-width: 165/750*100vw;
      height: 60/750*100vw;
      line-height: 60/750*100vw;
      font-size: 24/750*100vw;
    }
  }
}
}

/* ===========================
チェックしたスタイル
=========================== */
.checked_items {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 105px;
  .ttl01 {
    margin-bottom: 26px;
  }
  .checked_items_list {
    display: flex;
    li {
      width: 180px;
      margin-right: 24px;
      a {
        display: block;
        .img_blk {
          img {
            display: block;
            width: 100%;
          }
        }
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (min-width: 751px) and (max-width: 1220px) {
.checked_items {
  margin: 0 auto 105/1000*100vw;
  .ttl01 {
    margin-bottom: 26/1000*100vw;
  }
  .checked_items_list {
    padding: 0 40/1000*100vw;
    li {
      width: 15%;
      margin-right: 2%;
    }
  }
}
}

@media only screen and (max-width: 750px) {
.checked_items {
  margin: 0 auto 106/750*100vw;
  .ttl01 {
    margin-bottom: 34/750*100vw;
  }
  .checked_items_list {
    overflow-x: scroll;
    li {
      flex-shrink: 0;
      width: 180/750*100vw;
      margin-right: 0;
      margin-left: 23/750*100vw;
      &:first-of-type {
        margin-left: 40/750*100vw;
      }
    }
  }
}
}

/* ===========================
ページトップ
=========================== */
.btn_page_top {
  width: 45px;
  height: 45px;
  background-color: rgba(0,0,0,.3);
  position: fixed;
  bottom: 60px;
  right: 30px;
  transition-duration: .3s;
  transform: translateY(150px);
  z-index: 10;
  a {
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      display: block;
      width: 14px;
    }
  }
  &.viewed {
    transform: translateY(0);
  }
}

@media only screen and (min-width: 751px) and (max-width: 1220px) {
.btn_page_top {
  bottom: 30px;
}
}

@media only screen and (max-width: 750px) {
.btn_page_top {
  width: 90/750*100vw;
  height: 90/750*100vw;
  bottom: 40/750*100vw;
  right: 40/750*100vw;
  a {
    span {
      width: 28/750*100vw;
    }
  }
}
}

/* ===========================
この商品を見ている人にオススメ
=========================== */
.recommend_items {
  width: 100%;
  max-width: 100%;
  margin: 0 auto 100px;
  background-color: #eeeeee;
  padding: 60px 0 10px;
  .ttl01 {
    margin-bottom: 28px;
  }
  .recommend_items_slider {
    width: 100%;
    max-width: 1461px;
    margin: 0 auto 60px;
    .slick-slide {
      margin: 0 34px;
      a {
        display: block;
      }
      .img_blk {
        width: 100%;
        margin-bottom: 14px;
        img {
          display: block;
          width: 100%;
        }
      }
      .txt_blk {
        span {
          display: block;
        }
        .item_name {
          font-size: 1.4rem;
          line-height: 1.4;
        }
        .item_price {
          font-size: 1.4rem;
          line-height: 1.4;
        }
      }
    }
    .slick-arrow {
      display: block;
      width: 16px;
      height: 26px;
      background-image: url(../../img/common/arrow_b.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 100px;
      z-index: 1;
      cursor: pointer;
      &.prev-arrow {
        transform: rotate(180deg);
        left: -48px;
      }
      &.next-arrow {
        right: -48px;
      }
    }
  }
}

@media only screen and (min-width: 1221px) and (max-width: 1600px) {
.recommend_items {
  .recommend_items_slider {
    max-width: 1160px;
    .slick-slide {
      margin: 0 20px;
    }
    .slick-arrow {
      top: 90px;
      &.prev-arrow {
        left: -20px;
      }
      &.next-arrow {
        right: -20px;
      }
    }
  }
}
}

@media only screen and (min-width: 751px) and (max-width: 1220px) {
.recommend_items {
  margin: 0 auto 100/1000*100vw;
  padding: 60/1000*100vw 0 10/1000*100vw;
  .ttl01 {
    margin-bottom: 28/1000*100vw;
  }
  .recommend_items_slider {
    margin: 0 auto 60/1000*100vw;
    padding: 0 40/1000*100vw;
    .slick-slide {
      margin: 0 20/1000*100vw;
      .img_blk {
        margin-bottom: 14/1000*100vw;
      }
      .txt_blk {
        .item_name {
          font-size: 14/1000*100vw;
        }
        .item_price {
          font-size: 14/1000*100vw;
        }
      }
    }
    .slick-arrow {
      width: 16/1000*100vw;
      height: 26/1000*100vw;
      top: 84/1000*100vw;
      z-index: 1;
      cursor: pointer;
      &.prev-arrow {
        transform: rotate(180deg);
        left: 20/1000*100vw;
      }
      &.next-arrow {
        right: 20/1000*100vw;
      }
    }
  }
}
}

@media only screen and (max-width: 750px) {
.recommend_items {
  margin: 0 auto 92/750*100vw;
  padding: 60/750*100vw 0 2/750*100vw;
  .ttl01 {
    font-size: 40/750*100vw;
    margin-bottom: 30/750*100vw;
  }
  .recommend_items_slider {
    margin: 0 auto 90/750*100vw;
    display: flex;
    width: 100%;
    overflow-x: scroll;
    li {
      flex-shrink: 0;
      width: 260/750*100vw;
      margin: 0 0 0 40/750*100vw;
      .img_blk {
        margin-bottom: 18/750*100vw;
        img {
          display: block;
          width: 100%;
        }
      }
      .txt_blk {
        span {
          display: block;
        }
        .item_name {
          font-size: 24/750*100vw;
          line-height: 1.33;
        }
        .item_price {
          font-size: 24/750*100vw;
          line-height: 1.33;
        }
      }
    }
  }
}
}

/* ===========================
最近チェックしたアイテム
=========================== */
.checked_items {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 100px;
  padding: 60px 0 10px;
  .ttl01 {
    margin-bottom: 26px;
  }
  .checked_items_list {
    display: flex;
    li {
      width: 180px;
      margin-right: 24px;
      a {
        display: block;
        .img_blk {
          img {
            display: block;
            width: 100%;
          }
        }
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (min-width: 751px) and (max-width: 1220px) {
.checked_items {
  margin: 0 auto 100/1000*100vw;
  padding: 0;
  .ttl01 {
    margin-bottom: 26/1000*100vw;
  }
  .checked_items_list {
    padding: 0 40/1000*100vw;
    li {
      width: 15%;
      margin-right: 2%;
    }
  }
}
}

@media only screen and (max-width: 750px) {
.checked_items {
  margin: 0 auto 92/750*100vw;
  padding: 0;
  .ttl01 {
    margin-bottom: 34/750*100vw;
  }
  .checked_items_list {
    overflow-x: scroll;
    &::after {
      content: "";
      display: block;
      width: 40/750*100vw;
      height: 1px;
      flex-shrink: 0;
    }
    li {
      flex-shrink: 0;
      width: 180/750*100vw;
      margin-right: 0;
      margin-left: 23/750*100vw;
      &:first-of-type {
        margin-left: 40/750*100vw;
      }
    }
  }
}
}